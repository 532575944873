<template>
<div class="card-box-full">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td align="left">
          Documents
        </td>
        <td align="right">
        </td>
      </tr>
    </table>


  </div>

  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- --------------------------------------- -->
  <div v-if="isDownloading">
    <div style="height: 15px"></div>
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- --------------------------------------- -->
  <div v-else style="overflow-x: auto;">
    <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
      <thead>
        <tr style="font-size: 13px; border: 2px solid transparent">
          <th style="text-align:left">Filename</th>
          <th style="text-align:left">Created</th>
          <th style="text-align:left">Updated</th>
          <th style="text-align:center">Publishing</th>
          <th style="text-align:center">File</th>
        </tr>
      </thead>
      <tbody style="font-size: 13px">
        <tr v-for="document in documents">
          <td><b>{{ document.filename }}</b><br><span style="font-size: 9px">pub/sub: <i>{{ document.topic }}</i></span></td>
          <td><timeago :datetime="document.created"></timeago></td>
          <td><timeago :datetime="document.last_published"></timeago></td>
          <td style="text-align:center">
            <span v-if="document.publish" class="badge badge-pill badge-success">yes</span>
            <span v-else class="badge badge-pill badge-secondary">no</span>
          </td>
          <td>
            <b><a :href="document.url" target="_blank">Download</a></b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
</template>


<script>
export default {
  name: 'Documents',
  data() {
    return {
      errorMessage: null,
      isDownloading: false,
      documents: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    downloadDocuments() {

      if (this.isDownloading) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloading = true;
      }

      // Fetch the allow lists
      var that = this;
      var method = "GET";
      var url = "https://document.platform.robocalls.ai/documents";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloading = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the lists
            try {
              var result = JSON.parse(body);
              that.processDocuments(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloading = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloading = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processDocuments(response) {
      // Make sure we received results
      if (!response || !response.documents || response.documents.length == 0) {
        this.errorMessage = "No documents were returned";
        return;
      }

      // Sort the documents
      // function compare(a, b) {
      //   if (parseInt(a.prefix) > parseInt(b.prefix)) {
      //     return 1;
      //   } else if (parseInt(a.prefix) == parseInt(b.prefix)) {
      //     if (a.type == "master") {
      //       return -1;
      //     }
      //   }
      //   return 0;
      // }
      // response.whitelists.sort(compare);

      // Store the documents
      this.documents = response.documents;
    }
  },
  mounted() {
    this.downloadDocuments();
  },
}
</script>
